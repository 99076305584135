/** @jsx jsx */
import { jsx, Box, Grid, useColorMode } from "theme-ui";
import { Activity, Edit3, Link } from "react-feather";
import ServiceCard from "./service-card";
import BoxDiagonal from "./box-diagonal";

const CosaPossoFare = () => {
  const [mode] = useColorMode();
  const isDark = mode === "dark";
  return (
    // <SectionWrapper id="writing" pattern={rain} darkPattern={darkRain}>
    <Box
      as="section"
      sx={{
        mt: 3,
        zIndex: 10,
      }}
    >
      <Grid gap={4} columns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]} sx={{}}>
        <ServiceCard
          //background="linear-gradient( 135deg,#9966ff,#7033ff )"
          background={
            // isDark ? "linear-gradient(135deg,#FFDD00, #FBB034);" : "linear-gradient(135deg,#09C6F9, #045DE9);"
            isDark
              ? "linear-gradient(45deg,#FBB034, #FFDD00);"
              : "linear-gradient(45deg, rgb(68, 0, 205), rgb(0, 155, 255));"
          }
          isDark={isDark}
          cardTitle={"Sviluppo"}
          cardIcon={<Link size={24} />}
          cardContent={`Collaboro con aziende informatiche per offrire servizi di
              sviluppo, consulenza e formazione IT sulle nuove tecnologie per
              utilizzarle nell’informatizzazione di aziende produttive.`}
        />

        <ServiceCard
          background={
            isDark
              ? "linear-gradient(45deg,#FBB034, #FFDD00);"
              : "linear-gradient(45deg, rgb(68, 0, 205), rgb(0, 155, 255));"
          }
          //background="linear-gradient(135deg,rgb(0, 155, 255), rgb(68, 0, 255));"
          isDark={isDark}
          cardTitle={"Innovazione"}
          cardIcon={<Activity size={24} />}
          cardContent={`Aiuto le aziende IT nella migrazione dei loro prodotti verso le
              nuove tecnologie anticipando le innovazioni del settore
              tecnologico e informatico condividendole con il mercato.`}
        />

        <ServiceCard
          background={
            isDark
              ? "linear-gradient(45deg,#FBB034, #FFDD00);"
              : "linear-gradient(45deg, rgb(68, 0, 205), rgb(0, 155, 255));"
          }
          //background="linear-gradient(135deg, #f26, #f41);"
          isDark={isDark}
          cardTitle={"Qualità"}
          cardIcon={<Edit3 size={24} />}
          cardContent={`Scrivo codice di qualità sviluppando il sofware in tutti i suoi
              aspetti, dalla concezione al delivery.`}
        />
      </Grid>
    </Box>
    // </SectionWrapper>
  );
};

export default CosaPossoFare;
