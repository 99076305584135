/** @jsx jsx */
import { jsx, useColorMode, Text } from "theme-ui";
import { Link } from "gatsby";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title";
import Listing from "@lekoarts/gatsby-theme-minimal-blog/src/components/listing";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import Hero from "./hero-box";
import CosaPossoFare from "../../../components/cosa-posso-fare";
import React from "react";
import BoxDiagonal from "../../../components/box-diagonal";


type PostsProps = {
  nodes: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    draft: boolean;
    tags?: {
      name: string;
      slug: string;
    };
  };
};
type HomePageProps = {
  data: {
    pinned: PostsProps[];
    posts: PostsProps[];
  };
};

const Homepage = ({ data: { posts, pinned } }: HomePageProps): React.ReactFragment => {
  const { basePath, blogPath } = useMinimalBlogConfig();
  /*   React.useEffect(() => {
    fetch("/.netlify/functions/hello")
      .then((res) => res.json())
      .then(console.log);
  }, []); */

  console.log('posts:', { posts} )
  return (
    <Layout>
      <section sx={{ mb: [1, 1, 1], p: { fontSize: [1, 2, 3], mt: 0 } }}>
        <Hero />
      </section>
      <BoxDiagonal>
        <Title text="Mi occupo di:">
          <CosaPossoFare />
        </Title>
      </BoxDiagonal>
      {/*       <Title text="Featured Posts" />
      <Listing posts={pinned.nodes} showTags={false} /> */}
      <Title text="Latest Posts">
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>Read all posts</Link>
      </Title>
      <Listing posts={posts.nodes} showTags={false} />
    </Layout>
  );
};

export default Homepage;
