/** @jsx jsx */
import { jsx, Flex, Box, Text, Heading, Grid, Span } from "theme-ui";
import { darken } from "@theme-ui/color";
import { ArrowRight } from "react-feather";
import { motion } from "framer-motion";

const ServiceCard = ({ isDark, cardIcon, cardContent, cardTitle, background }) => (
  <motion.div
    as="div"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    sx={{
      borderWidth: 0,
      borderTopLeftRadius: 12,
      borderBottomRightRadius: 12,
      boxShadow: "0px 8px 36px 0px rgba(0,0,0,0.1)",
      background,
      flexDirection: "column",
      transition: "all .1s ease-in",
      "&:hover": {
        top: "-2px",
        boxShadow: "0px 8px 36px 0px rgba(0,0,0,0.5)",
      },
    }}
  >
    <Grid
      columns={"1fr 4fr"}
      sx={{
        alignItems: "center",
        borderTopLeftRadius: 12,
        backgroundColor: isDark ? "muted" : "white",
        py: 3,
        m: 0,
      }}
    >
      <Flex
        sx={{
          justifySelf: "end",
          alignSelf: "center",
          color: "text",
        }}
      >
        {cardIcon}
      </Flex>

      <Heading
        as="h2"
        sx={{
          color: isDark ? "white" : "text",
          fontWeight: "600",
        }}
      >
        {cardTitle}
      </Heading>
    </Grid>

    <Box
      sx={{
        color: isDark ? "black" : "white",
        flex: 1,
      }}
    >
      <Text
        sx={{
          textAlign: "left",
          fontSize: 2,
          p: 4,
          fontWeight: "400",
        }}
      >
        {cardContent}
      </Text>
    </Box>
  </motion.div>
);

export default ServiceCard;
